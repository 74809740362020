import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services" >
                <ul className="one-half first">
                    <li>
                        <a href='/'>Home</a>
                    </li>
                    <li>
                        <a href='/aboutoverview'>About Us</a>
                    </li>
                    <li>
                        <a href='/services-personal'>Services</a>
                    </li>
                    <li>
                        <a href='/contact'>Contact Us</a>
                    </li>
                </ul>
                {/* <!-- /.one-half --> */}
                {/* <ul className="one-half">
                    <li><Link to="/" onClick={() => { window.location.href = "/" }} title="">Home</Link></li>
                </ul> */}
                {/* <!-- /.one-half --> */}
            </div>
            /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;