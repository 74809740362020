import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class ServicesBoxs1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    classicon: 'icon-rounded clipboard',
                    title: 'Personal Tax Return',
                    description: 'Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations',
                    data: {
                        id: '1',
                        imgsrc: 'images/imagebox/Personal/Personal_Tax_Return.png',
                        title: 'Personal Tax Return',
                        we_offer_desc: 'Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations',
                        description: 'Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations',
                        details: {
                            title: "Personal Tax Return",
                            title_description: "Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations",
                            available_services_title: "Our Personal Tax Return Services:",
                            available_services: [
                                "Income Tax filing for individuals",
                                "Student Return",
                                "Capital Gain",
                                "Rental Income",
                                "Non Residence",
                                "Final Return for Deceased",
                                "Truck and Transport",
                                "Taxi/Limousine Driver/Owner",
                                "Independent Contractor",
                                "Self Employee/HST Return",
                                "Person with Foreign Income",
                                "Prior Year Tax Return",
                                "Return Adjustment"
                            ]
                        }
                    } 
                },
                {
                    id: 2,
                    classicon: 'icon-rounded line-chart',
                    title: 'INVESTMENT & INCOME REPORTING',
                    description: 'We specialize in aiding you through the maze of documenting investment profits, capital gains, and rental earnings, ensuring clarity and compliance in every step of the way.',
                    data: {
                        id: '1',
                        imgsrc: 'images/imagebox/Personal/INVESTMENT_R.png',
                        title: 'Investment and Income Reporting',
                        we_offer_desc: '',
                        description: 'We specialize in aiding you through the maze of documenting investment profits, capital gains, and rental earnings, ensuring clarity and compliance in every step of the way.',
                        details: {
                            title: "Investment and Income Reporting",
                            title_description: "",
                            available_services_title: "Investment and Income Reporting Services",
                            available_services: [
                                "Investment Income Reporting: Interest, dividends, capital gains, and rental income",
                                "Tax Planning for Investment.",
                                "Capital Gains and Losses Calculation",
                                "Foreign Income Reporting",
                                "Income Splitting Strategies",
                                "Rental Income Reporting",
                                "Tax Withholding and Reporting for Investments.",
                                "Tax Optimization for Business Income",
                                "Tax Implications of Retirement income sources"
            
                            ]
                        }
                    }
                },
                {
                    id: 3,
                    classicon: 'icon-rounded clock',
                    title: 'RRSP and DEDUCTIONS',
                    description: 'We will make sure to optimize your tax by identifying and claiming all eligible deductions and credits, including RRSP contributions, medical costs, and charitable donations, ensuring you receive the full benefits you’re entitled',
                    data: 
                    {
                        id: '1',
                        imgsrc: 'images/imagebox/Personal/RRSP_S.png',
                        title: 'RRSP and Deductions',
                        we_offer_desc: '',
                        description: 'We will make sure to optimize your tax by identifying and claiming all eligible deductions and credits, including RRSP contributions, medical costs, and charitable donations, ensuring you receive the full benefits you’re entitled',
                        details: {
                            title: "RRSP and Deductions",
                            title_description: "We will make sure to optimize your tax by identifying and claiming all eligible deductions and credits, including RRSP contributions, medical costs, and charitable donations, ensuring you receive the full benefits you’re entitled",
                            available_services_title: "RRSP & Deductions Services",
                            available_services: [
                                "RRSP Contribution Planning",
                                "Contribution Deadline Reminders",
                                "Investment Guidance",
                                "Tax Deduction Optimization",
                                "Spousal RRSP Strategies",
                                "RRSP Withdrawal  strategies",
                                "Home Buyers Plan (HBP) and Lifelong Learning Plan (LLP)",
                                "Estate Planning with RRSPs"
            
                            ]
                        }
                    }
                },
            ],
        }
    }

    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header" onClick={() => { }}>
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to={{ pathname: 'servicesdetails', state: { data: data.data } }}>{data.data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                    </div>
                                    {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                {/* <!-- /.iconbox --> */}
                            </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ServicesBoxs1;