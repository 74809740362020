import React, { useState, useEffect, useRef, createRef } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";

const PersonalService = (props) => {

    const { state } = useLocation();

    const history = useHistory();
    //const cardRefs = useRef([]);
    const [cardRefs, setCardRefs] = useState([]);
    const [imagebox] = useState([
        {
            id: '1',
            imgsrc: 'images/imagebox/Personal/Personal_Tax_Return.png',
            title: 'Personal Tax Return',
            we_offer_desc: 'Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations',
            description: 'Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations',
            details: {
                title: "Personal Tax Return",
                title_description: "Our expertise lies in handling all federal and provincial tax paperwork, guaranteeing accuracy and full compliance with regulations",
                available_services_title: "Our Personal Tax Return Services:",
                available_services: [
                    "Income Tax filing for individuals",
                    "Student Return",
                    "Capital Gain",
                    "Rental Income",
                    "Non Residence",
                    "Final Return for Deceased",
                    "Truck and Transport",
                    "Taxi/Limousine Driver/Owner",
                    "Independent Contractor",
                    "Self Employee/HST Return",
                    "Person with Foreign Income",
                    "Prior Year Tax Return",
                    "Return Adjustment"
                ]
            }
        },
        {
            id: '2',
            imgsrc: 'images/imagebox/Personal/INVESTMENT_R.png',
            title: 'Investment and Income Reporting',
            we_offer_desc: '',
            description: 'We specialize in aiding you through the maze of documenting investment profits, capital gains, and rental earnings, ensuring clarity and compliance in every step of the way.',
            details: {
                title: "Investment and Income Reporting",
                title_description: "",
                available_services_title: "Investment and Income Reporting Services",
                available_services: [
                    "Investment Income Reporting: Interest, dividends, capital gains, and rental income",
                    "Tax Planning for Investment.",
                    "Capital Gains and Losses Calculation",
                    "Foreign Income Reporting",
                    "Income Splitting Strategies",
                    "Rental Income Reporting",
                    "Tax Withholding and Reporting for Investments.",
                    "Tax Optimization for Business Income",
                    "Tax Implications of Retirement income sources"

                ]
            }
        },
        {
            id: '3',
            imgsrc: 'images/imagebox/Personal/RRSP_S.png',
            title: 'RRSP and Deductions',
            we_offer_desc: '',
            description: 'We will make sure to optimize your tax by identifying and claiming all eligible deductions and credits, including RRSP contributions, medical costs, and charitable donations, ensuring you receive the full benefits you’re entitled',
            details: {
                title: "RRSP and Deductions",
                title_description: "We will make sure to optimize your tax by identifying and claiming all eligible deductions and credits, including RRSP contributions, medical costs, and charitable donations, ensuring you receive the full benefits you’re entitled",
                available_services_title: "RRSP & Deductions Services",
                available_services: [
                    "RRSP Contribution Planning",
                    "Contribution Deadline Reminders",
                    "Investment Guidance",
                    "Tax Deduction Optimization",
                    "Spousal RRSP Strategies",
                    "RRSP Withdrawal  strategies",
                    "Home Buyers Plan (HBP) and Lifelong Learning Plan (LLP)",
                    "Estate Planning with RRSPs"

                ]
            }
        },
        {
            id: '4',
            imgsrc: 'images/imagebox/Personal/TAX_PLANNING.png',
            title: 'Tax Planning and Investments',
            we_offer_desc: '',
            description: 'Together, we will craft a tax strategy tailored to your needs, aiming to minimize your tax obligations over time and secure a more financially efficient future.',
            details: {
                title: "Tax Planning and Investments",
                title_description: "Together, we will craft a tax strategy tailored to your needs, aiming to minimize your tax obligations over time and secure a more financially efficient future.",
                available_services_title: "",
                available_services: [
                    " Personalized Tax Planning",
                    "Investment Advice",
                    "Capital Gains Tax Management",
                    "Estate Planning",

                ]
            }
        },
        {
            id: '5',
            imgsrc: 'images/imagebox/Personal/CRA.png',
            title: 'CRA Audit assistance',
            we_offer_desc: '',
            description: 'In the event of CRA Audit, count on us to provide expert guidance and advocate on your behalf throughout the process, ensuring your interests are fully protected',
            details: {
                title: "CRA Audit assistance",
                title_description: "In the event of CRA Audit, count on us to provide expert guidance and advocate on your behalf throughout the process, ensuring your interests are fully protected",
                available_services_title: "",
                available_services: [
                    "Audit Representation",
                    "Tax Compliance: Ensuring clients comply with all relevant tax laws and regulations.",
                    "Tax Planning: Strategies to minimize tax liabilities while remaining compliant with CRA regulations.",
                    "Record Keeping: Helping clients maintain accurate financial records and documentation.",
                    "Tax Appeals: Assisting clients in challenging CRA assessments or decisions through the appeals process.",
                    "Tax Risk Management: Identifying and mitigating potential tax risks for clients",
                    "Tax Training and Education."

                ]
            }
        }
    ]);

    useEffect(() => {

        setCardRefs(Array(imagebox.length).fill().map((_, i) => cardRefs[i] || createRef()));
    }, [imagebox]);

    useEffect(() => {
        if (state) {
            if (state.data) {
                if (state.data && cardRefs.length > 0) {
                    let id = (state.data.id) - 1;
                    cardRefs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    console.log("scrolling happens.....");
                }
            }
        }
    }, [cardRefs])


    return (
        <section className="flat-row pd-services-post">
            <div className="container">
                <div className="row">
                    {/*   <div className="col-md-12">
                        <div className="wrap-imagebox-grid">
                            {imagebox.map(data => (
                                <div className="flat-imagebox services-grid item" key={data.id}>
                                    <div className="flat-imagebox-inner">
                                        <div className="flat-imagebox-image">
                                            <img src={data.imgsrc} alt="img" />
                                        </div>
                                        <div className="flat-imagebox-header">
                                            <h3 className="flat-imagebox-title">
                                                <Link to="#">{data.title}</Link>
                                            </h3>
                                        </div>
                                        <div className="flat-imagebox-content">
                                            <div className="flat-imagebox-desc">{data.description}</div>
                                           
                                        </div>
                                        <div className='col-sm-12 low-read'>
                                            <div className="flat-imagebox-button">
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}

                    {imagebox.map((data, i) => (

                        <div
                            key={data.id}
                            ref={cardRefs[i]}
                            className='col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch'>
                            <div class="card flat-imagebox services-grid" style={{ width: '100%', marginTop: '20px' }}>
                                <img src={data.imgsrc} class="card-img-top img-responsive overlay-image" style={{ width: '100%' }} alt="..." />
                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-12 col-md-12'>
                                            <h3 class="card-title image-box-card-title" style={{ marginTop: '20px' }}>
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>{data.title}</Link>
                                            </h3>
                                            <p class="card-text" style={{ color: '#666', marginTop: '20px', textShadow: '0 0 0' }}>{data.description}</p>
                                        </div>
                                    </div>
                                    <div className='row mt-3 mb-3'>
                                        <div className='col-sm-12 col-md-12 col-lg-12' style={{ position: 'absolute', bottom: '0' }}>

                                            <div className="flat-imagebox-button">
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>Read More</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    );
};

export default PersonalService;
