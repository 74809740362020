import React, { Component } from 'react';
import { Link } from "react-router-dom";
class Team extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menutab: [
                {
                    id: 1,
                    title: 'Corporate Tax Planning',
                    link: '/services-business'
                },
                {
                    id: 2,
                    title: 'Accounting',
                    link: '/services-business'
                },
                {
                    id: 3,
                    title: 'Payroll Services ',
                    link: '/services-business'
                },
                {
                    id: 1,
                    title: 'Personal Tax Return',
                    link: '/services-personal'
                },
                {
                    id: 2,
                    title: 'Investment and Income Reporting',
                    link: '/services-personal'
                },
                {
                    id: 3,
                    title: 'RRSP and Deductions',
                    link: '/services-personal'
                },

            ],
            titletab: [
                {
                    id: 1,
                    title: 'Our Brochure',
                    description: 'View our 2016 financial prospectus brochure for an easy to read guide on all of the services offered.'
                }
            ]
        }
    }
    render() {
        return (
            <div className="col-md-3">
                <div className="sidebar left">
                    <aside className="widget widget_nav_menu">
                        <div className="menu-services-container">
                            <ul className="menu">
                                {
                                    this.state.menutab.map(data => (
                                        <Link to={{ pathname: data.link, state: { data } }}  >
                                            <li className={data.class} key={data.id} >{data.title}</li>
                                        </Link>
                                    ))
                                }
                            </ul>
                        </div>
                    </aside>


                </div>
            </div>
        )

    }
}

export default Team;