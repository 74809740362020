import React, { Component } from 'react';
import { Header, TopBar, Footer, Loader } from '../layouts/general';
import { Link } from "react-router-dom";
import axios from 'axios';

class Contact01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog',
                }
            ],
            titleheading: [
                {
                    id: '1',
                    title: 'Contact'
                }
            ],
            breadcrumbs: [
                {
                    id: 1,
                    title: 'Home',
                    classicon: 'fa fa-angle-right',
                    aria: 'true'
                },
                {
                    id: 2,
                    title: 'Contact',
                    classicon: '',
                    aria: ''
                }
            ],
            contactinfo: [
                {
                    id: '1',
                    title: 'Address',
                    info: '418 Hanlan Rd, Unit 4,',
                    details: 'Bulding A,  Vaughan, ON L4L 4Z1'
                },
                {
                    id: '2',
                    title: 'Phone number',
                    info: 'Call us: 416-763-1354'
                },
                {
                    id: '3',
                    title: 'E-mail address',
                    info: 'info@fandsconsulting.ca'
                }
            ],
            formData: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            infoMessage: '',
            infoClass: 'text-danger'
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    };

    handleSubmit = (e) => {

     
        console.log(">>>", axios.defaults)

        debugger;
        // Form validation
        const { name, email, subject, message } = this.state.formData;
        if (!name || !email || !subject || !message) {
            // Handle form validation error, maybe display a message to the user
            return;
        }

        // API call
        try {

           axios.post('/send-email', {
                name,
                email,
                subject,
                body: message
            }).then((response) => {
                // Handle successful API response, maybe display a success message
                console.log('Email sent successfully:', response.data);

                this.setState({
                    infoMessage: "Email Sent Successfully.",
                    infoClass: 'text-success',
                    formData: {
                        name: '',
                        email: '',
                        subject: '',
                        message: ''
                    }
                })

                setTimeout(() => {
                    this.setState({
                        infoMessage: "",
                        infoClass: 'text-danger',
                    })
                }, 1500);
            }).catch((error) => {
                this.setState({
                    infoMessage: "Error sending email",
                    infoClass: 'text-danger'
                })
                console.error('Error sending email:', error);
            });


        } catch (error) {
            // Handle API call error, maybe display an error message
            this.setState({
                infoMessage: "Error sending email",
                infoClass: 'text-danger'
            })
            console.error('Error sending email:', error);
        }



    };

    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                    {/*   <Loader /> */}
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }

                    <div className="page-title">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="page-title-heading">
                                        {
                                            this.state.titleheading.map(data => (
                                                <h1 key={data.id} className="h1-title">{data.title}</h1>
                                            ))
                                        }
                                    </div>
                                    <ul className="breadcrumbs">
                                        {
                                            this.state.breadcrumbs.map(data => (
                                                <li key={data.id} ><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                            ))
                                        }
                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="flat-row pd-contact">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="contact-info">
                                        {
                                            this.state.contactinfo.map(data => (
                                                <div className="info info-address" key={data.id} >
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.info}</p>
                                                    <p>{data.details}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="col-md-8">
                                    <div className="flat-form-info">
                                        <form id="contactform" onSubmit={(e) => { e.preventDefault()}} className="form-info">
                                            <div className="one-half v3">
                                                <p className="input-info"><input type="text" name="name" id="name" placeholder="Name" required="required" value={this.state.formData.name} onChange={this.handleChange} /></p>
                                                <p className="input-info"><input type="email" name="email" id="email" placeholder="Email" required="required" value={this.state.formData.email} onChange={this.handleChange} /></p>
                                                <p className="input-info"><input type="text" name="subject" id="subject" placeholder="Subject" required="required" value={this.state.formData.subject} onChange={this.handleChange} /></p>
                                                <p className="input-info"><button type="submit" className="submit" onClick={(e)=>{e.preventDefault(); this.handleSubmit(e) }} >Send Message</button></p>
                                            </div>
                                            <div className="one-half v4">
                                                <p className="input-info"><textarea id="message-contact" name="message" placeholder="Message" required="required" value={this.state.formData.message} onChange={this.handleChange}></textarea></p>
                                            </div>
                                            <div className='text-danger'>
                                                <p className={this.state.infoClass}>{this.state.infoMessage}</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="flat-row pdmap">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.066767890652!2d-79.55364161778863!3d43.77147283875588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2ffdb331485b%3A0xca7598614ffbe318!2s418%20Hanlan%20Rd%20Unit%204%2C%20Building%20A%2C%20Vaughan%2C%20ON%20L4L%204Z1%2C%20Canada!5e0!3m2!1sen!2slk!4v1710106420121!5m2!1sen!2slk" style={{ width: '100%', height: '400px' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </section>

                    <Footer />
                </div>
            </div>
        );
    }
}

export default Contact01;
