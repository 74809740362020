const menus = [
    {
        id: 1,
        name: 'Home',
        isSubMenu:false,
        linkmenu: '/'
    },
    {
        id: 2,
        name: 'About',
        isSubMenu:false,
        linkmenu: '/aboutoverview',
    },
    {
        id: 3,
        name: 'Services',
        isSubMenu:true,
        linkmenu: '/services-personal',
        namesub:  [
            {
                id: 1,
                sub: 'Business',
                links: '/services-business',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'Personal',
                links: '/services-personal',
                submenu: undefined
            }
        ],
    },
    {
        id: 6,
        name: 'Contact',
        isSubMenu:false,
        linkmenu: '/contact'
    }
]

export default menus;