import React, { useEffect, useState,createRef} from 'react';
import { Link, useLocation } from "react-router-dom";
import Footer from '../Footer';
import Loader from '../Loader';
import TopBar from '../TopBar';
import Header from '../Header';
import SlideBar from './SlideBar';
import BusinessServiceMainPost from './BusinessServiceMainPost';

const BusinessServiceDetails = () => {

    const test = createRef();

    const initialState = {
        headers: [
            {
                id: 1,
                names: 'Pages'
            }
        ],
        titleheading: [
            {
                id: '1',
                title: 'ServicesBusiness'
            }
        ],
        breadcrumbs: [
            {
                id: 1,
                title: 'Home',
                classicon: 'fa fa-angle-right',
                aria: 'true'
            },
            {
                id: 2,
                title: 'ServicesBusinessBusiness',
                classicon: 'fa fa-angle-right',
                aria: 'true'
            },
            {
                id: 3,
                title: 'Services',
                classicon: '',
                aria: ''
            }
        ],
        imagebox: [
        ]
    };
    const [my_initialstate, setInitialState] = useState(initialState);
    const location = useLocation();
    const task = location.state ? location.state : '';

    useEffect(() => {
        if (task) {
            let image_box = [];
            Object.entries(task.data).map(_task => {
                image_box.push(_task);
            });

            let modifiedData = { ...initialState, imagebox: [{ ...task.data }] }
            setInitialState(modifiedData)
            if(test){
            test.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }

        }
    }, [location])

    useEffect(()=>{
        try {
            window.responsiveMenu();    
        } catch (error) {
            console.log("Error handling jquery",error);
        }
        
    },[])


    return (
        <div className="bg-body3" ref={test}>
            <div className="boxed">

                <TopBar />
                {my_initialstate.headers.map(data => (
                    <Header data={data} key={data.id} />
                ))}

                <div className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    my_initialstate.imagebox.map(data => (
                                        <div className="title-section center s1" key={data.id} >
                                            <h2>What can we offer </h2>
                                            <p className="sub-title-section">{data.we_offer_desc} </p>
                                        </div>
                                    ))
                                }
                                <div className="dividers dividers-imagebox"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="flat-row pd-services-widget">
                    <div className="container">
                        <div className="row flat-tabs" data-effect="fadeIn">
                            <BusinessServiceMainPost data={my_initialstate.imagebox}/>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </div>
    );
};

export default BusinessServiceDetails;
