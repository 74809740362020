import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright">
                                    <p>F & S Consulting Services Copyright 2024. Developed by <a href='https://www.codeinis.io/' target='_blank'>Codeinis.io </a></p>
                                </div>
                                <ul className="menu-footer">
                                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                                </ul>
                                {/* <!-- /.menu-footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
                /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;