
import React, { useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';


export default function ItemWithoutThumbnailsDemo(props) {
    const [images, setImages] = useState(props.data);

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    useEffect(() => {

        let images = props.data.map((data) => {

            let singleValue = {
                itemImageSrc: data.srcimg,
                alt: '',
                title: data.slide_title
            }
            return singleValue;
        })


        /* let images = [{
            itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.jpg',
            thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg',
            alt: 'Description for Image 1',
            title: 'Title 1'
        },
        {
            itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria2.jpg',
            thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.jpg',
            alt: 'Description for Image 1',
            title: 'Title 1'
        }]; */
        setImages(images)

    }, [props.data]);

    const itemTemplate = (item) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    }

    const caption = (item) => {
        return (
            <React.Fragment>
                <div className="text-xl mb-2 font-bold">{item.title}</div>
            </React.Fragment>
        );
    }

    return (
        <div className="card">
            <Galleria value={images} responsiveOptions={responsiveOptions}  numVisible={5} circular autoPlay style={{ width: '100%', maxWidth: '100vw' }}
                showThumbnails={false} caption={caption} showItemNavigators item={itemTemplate} thumbnail={thumbnailTemplate} />
        </div>
    )
}
