import React, { useEffect } from "react";
import {BrowserRouter, Route} from 'react-router-dom'
import routes from './components/pages/index'
import axios from "axios";

function App() {


  useEffect(()=>{
    axios.defaults.baseURL= "https://fandspapi.vercel.app";
    console.log(">>>>",axios.defaults);
  },[]);

  return (
      <BrowserRouter >
        {
          routes.map((data,idx) => (
            <Route exact path={data.path} component={data.component} key={idx}></Route>
          ))
        }
      </BrowserRouter>
     
  );
}


export default App;
