import React, { Component } from 'react';
import Gallery from '../../pages/Gallery';

class BoxView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/imagebox/about/Welcome_to_F_&_S_consulting_services.png',
                    slide_title: 'Welcome to F & S consulting services, your  trusted partner for comprehensive tax services in GTA'
                },
                {
                    id: '2',
                    srcimg: 'images/imagebox/about/Our_tax_professionals_have_years_of_experience.png',
                    slide_title: 'What sets us apart?  Our genuine connections with every client and foundation of exceptional work and transparent communication'
                },
                {
                    id: '3',
                    srcimg: 'images/imagebox/about/Rely_on_us_to_deliver_outstanding_services.png',
                    slide_title: 'We understand time is money and your time is valuable. Let us handle your finances and maximize your returns while you grow your business'
                },
                {
                    id: '4',
                    srcimg: 'images/imagebox/about/We_understand_time_is_money_and_your_time_is_valuable.png',
                    slide_title: "Our tax professionals have years of experience handling unique tax situations, from straightforward personal income tax filing to complex corporate tax planning"
                },
                {
                    id: '5',
                    srcimg: 'images/imagebox/about/What_sets_us_apart.png',
                    slide_title: 'Rely on us to deliver outstanding services tailored to your personal and business accounting needs.'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'Want to know more about F & S Consulting Services ?',
                    description: `F & S Consulting Services has been  proudly serving  individuals, professionals, small businesses, and corporations in the Greater Toronto Area since 1989.`,
                    description_1: `Our mission is simple:  Empower  our clients to achieve their financial objectives and optimize their returns.`,
                    description_2: `Reach out to us today and discover how we can help you navigate the complexities of tax and finances while you focus on what truly matters : Growing your wealth and achieving your dreams!!`
                }
            ],
            blogtimeline: [

            ]

        }
    }
    render() {
        return (
            <div>
                {/*  <div className="flexslider s2">
                    <div className="flat-slides">
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data => (
                                    <li key={data.id}>
                                        <img src={data.srcimg} alt="img" />
                                        <h5 className='slider-slide-title'>{data.slide_title}</h5>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div> */}
                <div className="flexslider s2">
                    <Gallery data={this.state.imgslide}></Gallery>
                </div>



                {
                    this.state.content.map(data => (
                        <div className="box-content" key={data.id} >
                            <div className="title">{data.title}</div>
                            <p>{data.description}</p>
                            <p>{data.description_1}</p>
                            <p>{data.description_2}</p>
                            <div className="dividers dividers-bc-v4"></div>
                        </div>
                    ))
                }

            </div>

        )

    }
}

export default BoxView;