import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i>
                        <a href="mailto:info@fandsconsulting.ca" target='_blank'>info@fandsconsulting.ca</a>
                    </li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i>
                        <a href="tel:416-763-1354">416-763-1354</a>
                    </li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link to="#" title="">418 Hanlan Rd, Unit 4, Bulding A,Vaughan, ON L4L 4Z1</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
            /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;