import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Header, TopBar, Footer, Loader } from '../layouts/general';
import { MainServices } from '../layouts/general/services';
import BusinessService from '../layouts/general/services/BusinessService';

const ServicesBusiness = () => {
    const headers = [
        {
            id: 1,
            names: 'Pages'
        }
    ];

    const titleheading = [
        {
            id: '1',
            title: 'ServicesBusiness'
        }
    ];

    const breadcrumbs = [
        {
            id: 1,
            title: 'Home',
            classicon: 'fa fa-angle-right',
            aria: 'true'
        },
        {
            id: 2,
            title: 'Services',
            classicon: 'fa fa-angle-right',
            aria: ''
        },
        {
            id: 3,
            title: 'Business',
            classicon: '',
            aria: 'true'
        }
    ];


    return (
        <div className="bg-body3">
            <div className="boxed">
                <TopBar />
                {headers.map(data => (
                    <Header data={data} key={data.id} />
                ))}
                <div className="page-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-title-heading">
                                    <h1 className="h1-title">BUSINESS</h1>
                                </div>
                                <ul className="breadcrumbs">
                                    {breadcrumbs.map(data => (
                                        <li key={data.id}><Link to="#" title="">{data.title}<i className={data.classicon} aria-hidden={data.aria}></i></Link></li>
                                    ))}
                                </ul>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <BusinessService />
                <Footer />
            </div>
        </div>
    );
};

export default ServicesBusiness;
