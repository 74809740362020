import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 4,
                    classicon: 'icon-rounded',
                    image:'images/imagebox/icons/Corparate_Tax_Planning.png',
                    title: 'Corporate Tax Planning',
                    description: 'We guarantee you the maximum refund possible on your tax return at low cost. We take pride in helping numerous corporations avoid CRA audits and secure favorable outcomes at various stages, including audits, post-audits, and objections.',
                    data:{
                        id: '1',
                        imgsrc: 'images/imagebox/Business/CORPORATE_TAX_PLANNING.png',
                        title: 'Corporate Tax Planning',
                        we_offer_desc: 'Canadian corporations are required to file corporate income tax (T2 return) every tax year even if their earning is nil, or no taxes are payable. This includes inactive corporations, non-profit organizations and tax-exempt corporations.',
                        description: 'We guarantee you the maximum refund possible on your tax return at low cost. We take pride in helping numerous corporations avoid CRA audits and secure favorable outcomes at various stages, including audits, post-audits, and objections.',
                        details: {
                            title: "Corporation Tax Return",
                            title_description: "Canadian corporations are required to file corporate income tax (T2 return) every tax year even if their earning is nil, or no taxes are payable. This includes inactive corporations, non-profit organizations and tax-exempt corporations.",
                            available_services_title: "Our Corporate Tax Accounting Services:",
                            available_services: [
                                "Filing corporate income tax returns",
                                "Handling corporate income tax election forms and designations",
                                "Estimating and calculating for diverse transactions",
                                "Managing audit assessment appeals",
                                "T4 and T4A, T5 and NR4 summaries and slips processing",
                                "Filing 'Treaty-based' income tax returns for non-residents",
                                "Leading and consulting on corporate reorganizations and other corporate tax planning strategies, including the $750,000 Capital Gains Exemption",
                                "Negotiating with the CRA and Ministry of Finance on our client's behalf",
                                "Orchestrating corporate income tax inquiries and government audits"
                            ]
                        }
                    }
                },
                {
                    id: 5,
                    classicon: 'icon-rounded',
                    image:'images/imagebox/icons/Accounting.png',
                    title: 'Accounting',
                    description: 'We specialize in understanding the intricacies of the Canadian tax system, offering comprehensive tax preparation and filing services that meet the requirements of individuals and businesses.  Let us take care of this non-strategic yet important function of managing your books and finances.',
                    data:{
                        id: '1',
                        imgsrc: 'images/imagebox/Business/ACCOUNTING.png',
                        title: 'Accounting ',
                        we_offer_desc: '',
                        description: 'We specialize in understanding the intricacies of the Canadian tax system, offering comprehensive tax preparation and filing services that meet the requirements of individuals and businesses.  Let us take care of this non-strategic yet important function of managing your books and finances.',
                        details: {
                            title: "Accounting",
                            title_description: "We specialize in understanding the intricacies of the Canadian tax system, offering comprehensive tax preparation and filing services that meet the requirements of individuals and businesses.  Let us take care of this non-strategic yet important function of managing your books and finances.",
                            available_services_title: "Our Accounting Services:",
                            available_services: [
                                "Monthly or quarterly bookkeeping services",
                                "Payroll Processing",
                                "Year-end government filings",
                                "Weekly customer invoicing, supplier bill payments",
                                "Weekly, monthly accounts payable and receivables",
                                "Budgeting and monthly reporting",
                                "Bank Reconciliations",
                                "Any other custom accounting services"
                            ]
                        }
                    }
                },
                {
                    id: 6,
                    classicon: 'icon-rounded ',
                    image:'images/imagebox/icons/Payroll.png',
                    title: 'Payroll Services',
                    description: 'At F & S consulting services Our team of skilled accountants and payroll specialists meticulously handles every aspect of your payroll. We calculate wages, deduct taxes and benefits, and ensure paychecks or direct deposits are issued accurately and promptly. Trust in our ability to streamline payroll operations and boost business efficiency.',
                    data:{
                        id: '1',
                        imgsrc: 'images/imagebox/Business/PAYROLL_SERVICES.png',
                        title: 'Payroll Services ',
                        we_offer_desc: '',
                        description: 'At F&S consulting services Our team of skilled accountants and payroll specialists meticulously handles every aspect of your payroll. We calculate wages, deduct taxes and benefits, and ensure paychecks or direct deposits are issued accurately and promptly. Trust in our ability to streamline payroll operations and boost business efficiency.',
                        details: {
                            title: "Payroll Services",
                            title_description: "At F&S consulting services Our team of skilled accountants and payroll specialists meticulously handles every aspect of your payroll. We calculate wages, deduct taxes and benefits, and ensure paychecks or direct deposits are issued accurately and promptly. Trust in our ability to streamline payroll operations and boost business efficiency.",
                            available_services_title: "Our Payroll Services:",
                            available_services: [
                                "Paystub and related summary",
                                "CRA remittance",
                                "Record of employment (ROE)",
                                "T4s and all required year end reporting ",
                                "Wage Garnishment",
                                "WSIB Reporting",
                            ]
                        }
                    }
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                        <div className="image-rounded">
                                            <img className='image-rounded-icon' src={data.image} width="35px" height="35px"/>
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                        <Link to={{ pathname: 'servicesdetails', state: { data: data.data } }}>{data.data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs2;