import React, { useState, useEffect, createRef } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";


const BusinessService = () => {
    const { state } = useLocation();
    const history = useHistory();
    const [cardRefs, setCardRefs] = useState([]);

    const [imagebox] = useState([
        {
            id: '1',
            imgsrc: 'images/imagebox/Business/CORPORATE_TAX_PLANNING.png',
            title: 'Corporate Tax Planning',
            we_offer_desc: 'Canadian corporations are required to file corporate income tax (T2 return) every tax year even if their earning is nil, or no taxes are payable. This includes inactive corporations, non-profit organizations and tax-exempt corporations.',
            description: 'We guarantee you the maximum refund possible on your tax return at low cost. We take pride in helping numerous corporations avoid CRA audits and secure favorable outcomes at various stages, including audits, post-audits, and objections.',
            details: {
                title: "Corporation Tax Return",
                title_description: "Canadian corporations are required to file corporate income tax (T2 return) every tax year even if their earning is nil, or no taxes are payable. This includes inactive corporations, non-profit organizations and tax-exempt corporations.",
                available_services_title: "Our Corporate Tax Accounting Services:",
                available_services: [
                    "Filing corporate income tax returns",
                    "Handling corporate income tax election forms and designations",
                    "Estimating and calculating for diverse transactions",
                    "Managing audit assessment appeals",
                    "T4 and T4A, T5 and NR4 summaries and slips processing",
                    "Filing 'Treaty-based' income tax returns for non-residents",
                    "Leading and consulting on corporate reorganizations and other corporate tax planning strategies, including the $750,000 Capital Gains Exemption",
                    "Negotiating with the CRA and Ministry of Finance on our client's behalf",
                    "Orchestrating corporate income tax inquiries and government audits"
                ]
            }
        },
        {
            id: '2',
            imgsrc: 'images/imagebox/Business/ACCOUNTING.png',
            title: 'Accounting ',
            we_offer_desc: '',
            description: 'We specialize in understanding the intricacies of the Canadian tax system, offering comprehensive tax preparation and filing services that meet the requirements of individuals and businesses.  Let us take care of this non-strategic yet important function of managing your books and finances.',
            details: {
                title: "Accounting",
                title_description: "We specialize in understanding the intricacies of the Canadian tax system, offering comprehensive tax preparation and filing services that meet the requirements of individuals and businesses.  Let us take care of this non-strategic yet important function of managing your books and finances.",
                available_services_title: "Our Accounting Services:",
                available_services: [
                    "Monthly or quarterly bookkeeping services",
                    "Payroll Processing",
                    "Year-end government filings",
                    "Weekly customer invoicing, supplier bill payments",
                    "Weekly, monthly accounts payable and receivables",
                    "Budgeting and monthly reporting",
                    "Bank Reconciliations",
                    "Any other custom accounting services"
                ]
            }
        },
        {
            id: '3',
            imgsrc: 'images/imagebox/Business/PAYROLL_SERVICES.png',
            title: 'Payroll Services ',
            we_offer_desc: '',
            description: 'At F&S consulting services Our team of skilled accountants and payroll specialists meticulously handles every aspect of your payroll. We calculate wages, deduct taxes and benefits, and ensure paychecks or direct deposits are issued accurately and promptly. Trust in our ability to streamline payroll operations and boost business efficiency.',
            details: {
                title: "Payroll Services",
                title_description: "At F&S consulting services Our team of skilled accountants and payroll specialists meticulously handles every aspect of your payroll. We calculate wages, deduct taxes and benefits, and ensure paychecks or direct deposits are issued accurately and promptly. Trust in our ability to streamline payroll operations and boost business efficiency.",
                available_services_title: "Our Payroll Services:",
                available_services: [
                    "Paystub and related summary",
                    "CRA remittance",
                    "Record of employment (ROE)",
                    "T4s and all required year end reporting ",
                    "Wage Garnishment",
                    "WSIB Reporting",
                ]
            }
        },
        {
            id: '4',
            imgsrc: 'images/imagebox/Business/BUSINESS_LICENSE.png',
            title: 'Business License and Incorparation',
            we_offer_desc: '',
            description: 'Setting up a business, registration and choosing the right legal structure for your entity is a time consuming and complex process. We can work collaboratively to evaluate and determine the optimal form of corporation suitable for your business.',
            details: {
                title: "Business License and Incorparation",
                title_description: "Setting up a business, registration and choosing the right legal structure for your entity is a time consuming and complex process. We can work collaboratively to evaluate and determine the optimal form of corporation suitable for your business.",
                available_services_title: "Our Business license and Incorporation Services:",
                available_services: [
                    "Tailored Incorporation Consultation",
                    "CRA Registration and Tax Submission",
                    "Shareholder Agreements Assistance",
                    "Incorporation Filing ",
                    "Maximized Tax Returns",
                    "Tax Planning and Optimization",
                ]
            }
        },
        {
            id: '5',
            imgsrc: 'images/imagebox/Business/PAYROLL_SETUP.png',
            title: 'Payroll SetUp and Training',
            we_offer_desc: '',
            description: 'Keep your bookkeeping in your hands, save money and run your business more efficiently',
            details: {
                title: "Payroll SetUp and Training",
                title_description: "We can train you to maintain your books with popular software like QuickBooks, Sage50, PC Law, Excel and other accounting software. ",
                available_services_title: "Our Business license and Incorporation Services:",
                available_services: [
                    "Payroll System Setup",
                    "Training and setting up Employee Onboarding",
                    "Payroll Training: Providing training sessions or workshops for business owners, managers, and payroll administrators on payroll processing best practices, tax compliance requirements, and how to effectively use payroll software or systems.",
                    "Compliance Monitoring: Monitoring changes in payroll tax laws and regulations to ensure businesses remain compliant with evolving requirements and advising on any necessary adjustments to payroll processes or procedures.",

                ]
            }
        }
    ]);

    useEffect(() => {

        setCardRefs(Array(imagebox.length).fill().map((_, i) => cardRefs[i] || createRef()));
    }, [imagebox]);

    useEffect(() => {
        if (state) {
            if (state.data) {
                if (state.data && cardRefs.length > 0) {
                    console.log("DATA", state.data);
                    let id = (state.data.id) - 1;
                    cardRefs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
            }
        }
    }, [cardRefs])

    return (
        <section className="flat-row pd-services-post">
            <div className="container">
                <div className="row">
                    {/*  <div className="col-md-12">
                        <div className="wrap-imagebox-grid">
                            {imagebox.map(data => (
                                <div className="flat-imagebox services-grid item" key={data.id}>
                                    <div className="flat-imagebox-inner">
                                        <div className="flat-imagebox-image">
                                            <img src={data.imgsrc} alt="img" />
                                        </div>
                                        <div className="flat-imagebox-header">
                                            <h3 className="flat-imagebox-title">
                                                <Link to="#">{data.title}</Link>
                                            </h3>
                                        </div>
                                        <div className="flat-imagebox-content">
                                            <div className="flat-imagebox-desc">{data.description}</div>
                                        </div>
                                        <div className='col-sm-12 low-read'>
                                            <div className="flat-imagebox-button">
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>Read More</Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}


                    {imagebox.map((data, i) => (
                        <div
                            key={data.id}
                            ref={cardRefs[i]}
                            className='col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch'>
                            <div class="card flat-imagebox services-grid" style={{ width: '100%', marginTop: '20px' }}>
                                <img src={data.imgsrc} class="card-img-top img-responsive overlay-image" style={{ width: '100%' }} alt="..." />
                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-12 col-lg-12 col-md-12'>
                                            <h3 class="card-title image-box-card-title" style={{ marginTop: '20px' }}>
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>{data.title}</Link>
                                            </h3>
                                            <p class="card-text" style={{ color: '#666', marginTop: '20px', textShadow: '0 0 0' }}>{data.description}</p>
                                        </div>
                                    </div>
                                    <div className='row mt-3 mb-3'>
                                        <div className='col-sm-12 col-md-12 col-lg-12' style={{ position: 'absolute', bottom: '0' }}>

                                            <div className="flat-imagebox-button">
                                                <Link to={{ pathname: 'servicesdetails', state: { data } }}>Read More</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </section>
    );
};

export default BusinessService;
