import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'
import { Footer,Header,Slider,  Loader,CarouselClient,  TopBar } from '../layouts/general';
import { Featured } from '../layouts/general/featured';
import { Services } from '../layouts/general/services';
import HomeSlider from './HomeSlider';
import CustomSlider from './CustomSlider';


class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           headers: [
               {
                   id: 1,
                   names: 'Home'
               }
           ],
           
        }

        console.log("version","1.0.7");


    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                   
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    {/* <HomeSlider /> */}
                  {/*   <CustomSlider/> */}
                    <Featured />
                    <Services />
                 

                    <CarouselClient />

                    <Footer />
                </div>
            </div>
        );
    }
}

export default withRouter(Home01);