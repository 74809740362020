import Home01 from './Home01'
import OverView from './OverView'
import AboutUs from './AboutUs'
import Services from './Services'
import RiskManagement from './RiskManagement'
import Contact01 from './Contact01'
import Appoinment from "./Appoinment"
import ServicesBusiness from './ServicesBusiness'
import ServicesPersonal from './ServicesPersonal'
import ServicesBusinessDetails from '../layouts/general/services/BusinessServiceDetails'



const routes = [
    { path: '/', component: Home01 },
    { path: '/aboutus', component: AboutUs },
    { path: '/aboutoverview', component: OverView },
    { path: '/services-personal', component: ServicesPersonal},
    { path: '/services-business', component: ServicesBusiness },
    { path: '/servicesdetails', component: ServicesBusinessDetails },
    { path: '/services-v2', component: RiskManagement },
    { path: '/contact', component: Contact01 },
    { path: "/make-booking", component: Appoinment }


]

export default routes;