import React, { useEffect, useState } from 'react';

const BusinessServiceMainPost = (props) => {

    const [image_box, setImageBoxDetails] = useState([]);
    useEffect(() => {
        setImageBoxDetails(props.data);
    }, [props])

    return (
        <div className="col-md-9 content-tab">
            {image_box.map(data => (
                <div className="content-inner" key={data.id}>
                    <div className={data.classcategory}>
                        <div className="col-sm-12 col-lg-12 col-md-12">
                            <div className="box-content">

                                <div key={data.id}>
                                    <div className="title">{data.details.title}</div>
                                    <p>{data.details.title_description}</p>
                                    <div className="dividers dividers-bc-v1"></div>
                                </div>

                                <ul className="flat-list">
                                    {data.details.available_services.map(item => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default BusinessServiceMainPost;
