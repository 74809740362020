import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>We do tax planning and preparation for individuals, executives and corporations . Whether you need a simple tax filing, or a sophisticated accounting service, we can help you meet your tax and accounting needs.
                </p>
                
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;