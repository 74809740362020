import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class TopBar extends Component {

    redirectToCalendy = ()=>{
        window.location = "https://calendly.com/fandsconsulting/30min?back=1&month=2024-03";
    }

    render() {
        return (
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="flat-infomation">
                                <li className="phone">Call us: <a href="tel:416-763-1354" target='_blank'>416-763-1354</a></li>
                                <li className="email">Email: <a href="mailto:info@fandsconsulting.ca" target='_blank'>info@fandsconsulting.ca</a></li>
                            </ul>
                            <div className="flat-questions">
                                <Link to="#" title="" className="questions" style={{cursor:'default'}}>Have any questions?</Link>
                                <Link to="/make-booking" title="" className="appointment" >GET AN APPOINTMENT</Link>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
		    </div>
        );
    }
}

export default TopBar;